@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@700&family=Source+Sans+Pro&display=swap");

@font-face {
    font-family: 'FatFrank-Free';
  src: url("../fonts/FatFrank-Free/FatFrank-Free.eot");
  src: url("../fonts/FatFrank-Free/FatFrank-Free.eot?#iefix") format("embedded-opentype"), url("../fonts/FatFrank-Free/FatFrank-Free.svg#FatFrank-Free") format("svg"), url("../fonts/FatFrank-Free/FatFrank-Free.ttf") format("truetype"), url("../fonts/FatFrank-Free/FatFrank-Free.woff") format("woff"), url("../fonts/FatFrank-Free/FatFrank-Free.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

a,
a:hover,
a:focus {
    text-decoration: none;
    color: #000000;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "FatFrank-Free", Arial, Helvetica, sans-serif;
}

img {
    max-width: 100%;
}

body {
    font-family: "Source Sans Pro", sans-serif;
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: normal;
    color: #383838;
    background-color: #e4e4e4;
}

.container {
    max-width: 1170px;
}

header {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #2d2b30;
}

header .navbar {
    margin: 0;
    padding: 0;
}

.navbar-dark .navbar-nav .nav-item {
    margin-right: 10px;
    margin-left: 10px;
}

.navbar-dark .navbar-nav .nav-item.active .nav-link {
    border-bottom: 1px solid #fff;
}

.navbar-dark .navbar-nav .nav-link {
    color: #ffffff;
    font-size: 15px;
    text-transform: uppercase;
    transition: 0.3s ease-in all;
    padding: 0;
    padding-bottom: 3px;
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:active, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.5);
}

.section-title h2 {
    font-size: 38px;
    text-transform: uppercase;
    margin-bottom: 25px;
}

.table-top {
    color: #777777;
}

.table-top thead tr > th {
    padding: 1.0rem;
    color: #383838;
    text-align: center;
    background-color: #efefef;
    font-family: "FatFrank-Free", Arial, Helvetica, sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

.table-top thead tr > th:first-child {
    border-top-left-radius: 5px;
}

.table-top thead tr > th:last-child {
    border-top-right-radius: 5px;
}

.table-top tbody tr td {
    background-color: #f6f6f6;
    border-top: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    vertical-align: middle;
    text-align: center;
    padding: 1.0rem;
}

.table-top tbody tr td.col-scor .score {
    font-family: "Oswald", sans-serif;
    font-size: 58px;
    color: #383838;
}

.table-top tbody tr td.col-site {
    position: relative;
}

.table-top tbody tr td.col-site img {
    max-width: 120px;
    margin-top: 20px;
}

.table-top tbody tr:nth-child(2n + 1) td {
    background-color: #ffffff;
}

.table-top .col-desc {
    max-width: 280px;
}

.table-top .col-desc h4 {
    color: #2d2b30;
}

.table-top .col-desc .rating-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.table-top .col-desc .rating-wrapper small {
    color: #8c8c8c;
    font-size: 11px;
    margin-left: 5px;
}

.section-review h4 {
    font-size: 22px;
    color: #383838;
    text-transform: uppercase;
}

.section-review .table-top tbody tr td {
    background-color: #fff;
}

.section-review .table-top tbody tr:nth-child(odd) td {
    background-color: #f6f6f6;
}

.rating {
    margin-bottom: 5px;
}

.review-lnk {
    text-transform: uppercase;
    color: #777777;
    font-size: 13px;
    text-decoration: underline;
}

.h-ribbon {
    position: absolute;
    width: 180px;
    max-width: 100%;
    text-transform: uppercase;
    color: #fff;
    font-family: "FatFrank-Free", Arial, Helvetica, sans-serif;
    background-color: #ff4f4f;
    height: 32px;
    line-height: 32px;
    text-align: left;
    text-indent: 10px;
    top: 0;
    left: 0;
    margin-left: -10px;
    margin-top: 10px;
}

.h-ribbon::after {
    content: "";
    display: block;
    border-top: 5px solid #c83939;
    border-left: 10px solid transparent;
    border-right: 0px solid transparent;
    position: absolute;
    left: 0;
    bottom: 0;
    margin-bottom: -5px;
}

.btn-theme {
    background-color: #ff4f4f;
    text-align: center;
    color: #ffffff;
    font-size: 28px;
    font-family: "Oswald", sans-serif;
    border-radius: 0;
    width: 189px;
    padding: 5px 16px;
    text-transform: uppercase;
    transition: 0.3s ease-in all;
}

.btn-theme:hover, .btn-theme:focus {
    background-color: rgba(255, 79, 79, 0.8);
    transform: scale(1.04);
    color: #ffffff;
}

.col-tryi > div > a {
    font-size: 13px;
    color: #777777;
    text-decoration: underline;
}

article {
    background-color: #ffffff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: #777777;
}

article .article-preview img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

article h3 {
    color: #383838;
    font-size: 24px;
    margin-bottom: 25px;
}

article .read-more {
    color: #777777;
    text-decoration: underline;
}

footer {
    background-color: #2d2b30;
    padding-top: 21px;
    padding-bottom: 21px;
}

footer .copyright {
    color: #fff;
    margin-bottom: 0;
}

.footer-nav li {
    margin-right: 10px;
    margin-left: 10px;
}

.footer-nav li a {
    color: #fff;
}

.sidebar .table-top thead tr th {
    background-color: #ff4f4f;
    color: #fff;
}

.sidebar .table-top tbody tr td.col-site .rating {
    margin-top: 5px;
    margin-bottom: 0;
}

.sidebar .table-top tbody tr td.col-scor {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 10px;
}

.sidebar .table-top tbody tr td.col-scor span {
    font-family: "Oswald", sans-serif;
    font-size: 43px;
}

.sidebar .table-top tbody tr td.col-scor div a {
    text-transform: uppercase;
    text-decoration: underline;
}

.second-title {
    text-transform: uppercase;
    font-size: 35px;
}

.page-title {
    font-size: 46px;
    text-transform: uppercase;
}

.last-update {
    color: #919191;
}

@media (max-width: 1024px) {
    footer > .container > .d-flex {
        flex-direction: column;
    }

    footer .copyright {
        font-size: 14px;
        text-align: center;
        margin-bottom: 15px;
    }

    .footer-nav li a {
        font-size: 12px;
    }

    .page-title {
        font-size: 34px;
    }

    .section-title h2,
  .second-title {
        font-size: 28px;
    }
}

@media (max-width: 990px) {
    .hero-profile {
        min-width: 180px;
    }
}

@media (max-width: 767px) {
    .table-top tbody tr td.col-scor .score {
        font-size: calc(2.125rem + ((1vw - 3.2px) * 5.3691));
    }

    .section-review .table-top tbody tr:nth-child(odd) td {
        background-color: #fff;
    }

    .table-top .btn-theme {
        width: auto;
        padding: 5px 10px;
        min-width: 130px;
        font-size: calc(1.125rem + ((1vw - 3.2px) * 2.2371));
    }

    .table-top tbody tr td {
        padding: 0.6rem;
    }

    .table-top.table-verticle .rating img {
        max-width: 16px;
    }

    .section-review .table-top.table-verticle tbody tr {
        margin-bottom: 0;
    }

    .section-review .table-top.table-verticle tbody tr td.col-tryi {
        border-radius: 0;
    }

    .section-review .table-top.table-verticle tbody tr:last-child td:first-child {
        border-bottom-left-radius: 5px;
    }

    .section-review .table-top.table-verticle tbody tr:last-child td:last-child {
        border-bottom-right-radius: 5px;
    }
}

@media (max-width: 600px) {
    .table-top thead {
        display: none;
    }

    .table-top .btn-theme {
        width: 80%;
    }

    .table-top.table-verticle tr {
        display: flex;
        flex-wrap: wrap;
        background-color: #fff;
        margin-bottom: 10px;
    }

    .table-top.table-verticle tr.has-ribbon {
        position: relative;
        margin-top: 30px;
    }

    .table-top.table-verticle tr.has-ribbon .h-ribbon {
        width: 100%;
        top: 0;
        left: 0;
        margin-left: 0;
        margin-top: -30px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .table-top.table-verticle tr.has-ribbon .h-ribbon:after {
        display: none;
    }

    .table-top.table-verticle tr td {
        background-color: #fff;
        border: none;
    }

    .table-top.table-verticle tr td.col-site {
        width: 35%;
        padding-bottom: 0px;
        position: unset;
    }

    .table-top.table-verticle tr td.col-site img {
        margin-top: 0px;
    }

    .table-top.table-verticle tr td.col-desc {
        padding-left: 0;
        max-width: 100%;
        width: 65%;
        padding-bottom: 0;
    }

    .table-top.table-verticle tr td.col-desc p {
        margin-bottom: 8px;
    }

    .table-top.table-verticle tr td.col-scor {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 35%;
        flex-direction: column;
    }

    .table-top.table-verticle tr td.col-scor .score {
        line-height: 20px;
        margin-top: -20px;
    }

    .table-top.table-verticle tr td.col-tryi {
        text-align: left;
        padding: 0;
        width: 50%;
        padding-bottom: 10px;
    }
}

@media (max-width: 560px) {
    .hero-header .row {
        flex-direction: column;
    }

    .hero-header .hero-profile {
        margin-bottom: 20px;
    }

    .logo {
        max-width: 200px;
    }

    .navbar-collapse {
        margin-top: 15px;
    }

    .navbar-dark .navbar-nav .nav-item {
        margin-bottom: 10px;
    }

    .navbar-dark .navbar-nav .nav-item.active .nav-link {
        display: inline-block;
    }
}

@media (max-width: 460px) {
    .table-top.table-verticle tbody tr td.col-rati {
        max-width: 50%;
    }

    .table-top.table-verticle tbody tr td.col-scor {
        max-width: 50%;
    }

    .table-top.table-verticle tbody tr td.col-tryi {
        max-width: 100%;
    }

    .table-top tbody tr td.col-site img {
        max-width: 100%;
    }
}
