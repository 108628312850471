@media (max-width: 1024px) {
  footer > .container > .d-flex {
    flex-direction: column;
  }
  footer .copyright {
    font-size: 14px;
    text-align: center;
    margin-bottom: 15px;
  }
  .footer-nav li a {
    font-size: 12px;
  }
  .page-title {
    font-size: 34px;
  }
  .section-title h2,
  .second-title {
    font-size: 28px;
  }
}

@media (max-width: 990px) {
  .hero-profile {
    min-width: 180px;
  }
}

@media (max-width: 767px) {
  .table-top tbody tr td.col-scor .score {
    font-size: calc(2.125rem + ((1vw - 3.2px) * 5.3691));
  }

  .section-review .table-top tbody tr:nth-child(odd) td {
    background-color: #fff;
  }

  .table-top .btn-theme {
    width: auto;
    padding: 5px 10px;
    min-width: 130px;
    font-size: calc(1.125rem + ((1vw - 3.2px) * 2.2371));
  }

  .table-top tbody tr td {
    padding: 0.6rem;
  }

  .table-top.table-verticle {
    .rating {
        img {
            max-width: 16px;
        }
    }
  }

  .section-review .table-top.table-verticle tbody tr {
      margin-bottom: 0;
  }
  .section-review .table-top.table-verticle tbody tr td.col-tryi  {
      border-radius: 0;
  }
  .section-review .table-top.table-verticle tbody tr:last-child {
      td:first-child {
          border-bottom-left-radius: 5px;
      }
      td:last-child {
        border-bottom-right-radius: 5px;
    }
  }
}

@media (max-width: 600px) {
  .table-top thead {
    display: none;
  }
  .table-top .btn-theme {
    width: 80%;
  }
  .table-top.table-verticle {
    tr {
      display: flex;
      flex-wrap: wrap;
      background-color: #fff;
      margin-bottom: 10px;

      &.has-ribbon {
        position: relative;
        margin-top: 30px;

        .h-ribbon {
          width: 100%;
          top: 0;
          left: 0;
          margin-left: 0;
          margin-top: -30px;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          &:after {
            display: none;
          }
        }
      }

      td {
        background-color: #fff;
        border: none;
      }

      td.col-site {
        width: 35%;
        padding-bottom: 0px;
        position: unset;

        img {
          margin-top: 0px;
        }
      }

      td.col-desc {
        padding-left: 0;
        max-width: 100%;
        width: 65%;
        padding-bottom: 0;
        p {
          margin-bottom: 8px;
        }
      }

      td.col-scor {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 35%;
        flex-direction: column;

        .score {
          line-height: 20px;
    margin-top: -20px;
        }
      }

      td.col-tryi {
        text-align: left;
        padding: 0;
        width: 50%;
        padding-bottom: 10px;
      }
    }
  }

  
}

@media (max-width: 560px) {
  .hero-header {
    .row {
      flex-direction: column;
    }

    .hero-profile {
      margin-bottom: 20px;
    }
  }

  .logo {
    max-width: 200px;
  }

  .navbar-collapse {
    margin-top: 15px;
  }

  .navbar-dark .navbar-nav .nav-item {
    margin-bottom: 10px;
  }

  .navbar-dark .navbar-nav .nav-item.active .nav-link {
    display: inline-block;
  }
}


@media (max-width: 460px) {
    .table-top.table-verticle tbody tr td.col-rati {
        max-width: 50%;
    }
    .table-top.table-verticle tbody tr td.col-scor {
        max-width: 50%;
    }
    .table-top.table-verticle tbody tr td.col-tryi {
        max-width: 100%;
    }
    .table-top tbody tr td.col-site img {
        max-width: 100%;
    }
}