a,
a:hover,
a:focus {
    text-decoration: none;
    color: $dark-color;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $title-font;
}

img {
    max-width: 100%;
}

body {
    font-family: $regular-font;
    margin: 0;
    padding: 0;
    font-size: $font-size;
    line-height: normal;
    color: $text-color;
    background-color: #e4e4e4;
}

.container {
    max-width: $container;
}

header {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: $nav-color;

    .navbar {
        margin: 0;
        padding: 0;
    }
}

.navbar-dark .navbar-nav {
    
    .nav-item {
        margin-right: 10px;
        margin-left: 10px;

        &.active {
            .nav-link {
                border-bottom: 1px solid #fff;
            }
        }
    }

    .nav-link {
        color: $white-color;
        font-size: 15px;
        text-transform: uppercase;
        transition: 0.3s ease-in all;
        padding: 0;
        padding-bottom: 3px;
    
        &:hover,
        &:active,
        &:focus {
            color: rgba($color: #fff, $alpha: 0.5);
        }
    }
}

.section-title {
    h2 {
        font-size: 38px;
        text-transform: uppercase;
        margin-bottom: 25px;
    }
}

.table-top {
    color: #777777;
    
    thead {

        tr > {
            th {
                padding: 1.0rem;
                color: #383838;
                text-align: center;
                background-color: #efefef;
                font-family: $title-font;
                font-size: 16px;
                text-transform: uppercase;
                letter-spacing: 0.1em;

                &:first-child {
                    border-top-left-radius: 5px;
                }

                &:last-child {
                    border-top-right-radius: 5px;
                }
            }
        }

    }

    tbody {
        tr {

            td {
                background-color: #f6f6f6;
                border-top: 1px solid #e4e4e4;
                border-bottom: 1px solid #e4e4e4;
                vertical-align: middle;
                text-align: center;
                padding: 1.0rem;

                &.col-scor {
                    .score {
                        font-family: $bold-font;
                        font-size: 58px;
                        color: $text-color
                    }
                    
                }

                &.col-site {
                    position: relative;

                    img {
                        max-width: 120px;
                        margin-top: 20px;
                    }
                }
            }

            &:nth-child(2n + 1) {
                td {
                    background-color: $white-color;
                }
            }
        }
    }

    .col-desc {
        max-width: 280px;

        h4 {
            color: #2d2b30;
        }

        .rating-wrapper {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            small {
                color: #8c8c8c;
                font-size: 11px;
                margin-left: 5px;
            }
        }
    }
} 

.section-review {
    h4 {
        font-size: 22px;
        color: #383838;
        text-transform: uppercase;
    }
    .table-top {
        tbody {
            tr {
                
                td {
                    background-color: #fff;
                }

                &:nth-child(odd) {
                    td {
                        background-color: #f6f6f6;
                    }
                }
            }   
        }
    }

}

.rating {
    margin-bottom: 5px;
}

.review-lnk {
    text-transform: uppercase;
    color: #777777;
    font-size: 13px;
    text-decoration: underline;
}

.h-ribbon {
    position: absolute;
    width: 180px;
    max-width: 100%;
    text-transform: uppercase;
    color: #fff;
    font-family: $title-font;
    background-color: #ff4f4f;
    height: 32px;
    line-height: 32px;
    text-align: left;
    text-indent: 10px;
    top: 0;
    left: 0;
    margin-left: -10px;
    margin-top: 10px;

    &::after {
        content: "";
        display: block;
        border-top: 5px solid #c83939;
        border-left: 10px solid transparent;
        border-right: 0px solid transparent;
        position: absolute;
        left: 0;
        bottom: 0;
        margin-bottom: -5px;

    }
}

.btn-theme {
    background-color: $theme-color;
    text-align: center;
    color: $white-color;
    font-size: 28px;
    font-family: $bold-font;
    border-radius: 0;
    width: 189px;
    padding: 5px 16px;
    text-transform: uppercase;
    transition: 0.3s ease-in all;

    &:hover,
    &:focus {
        background-color: rgba($color: $theme-color, $alpha: 0.8);
        transform: scale(1.04);
        color: $white-color;
    }
}

.col-tryi > div > a {
    font-size: 13px;
    color: #777777;
    text-decoration: underline;
}

article {
    background-color: $white-color;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: #777777;

    .article-preview {
        
        img {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
    }

    h3 {
        color: #383838;
        font-size: 24px;
        margin-bottom: 25px;
    }

    .read-more {
        color: #777777;
        text-decoration: underline;
    }
}

footer {
    background-color: #2d2b30;
    padding-top: 21px;
    padding-bottom: 21px;

    .copyright {
        color: #fff;
        margin-bottom: 0;
    }
}

.footer-nav {
    li {
        margin-right: 10px;
        margin-left: 10px;

        a {
            color: #fff;
        }
    }
}

.sidebar {
    .table-top {
        thead {
            tr {
                th {
                    background-color: #ff4f4f;
                    color: #fff;
                }
            }
        }
        tbody {
            tr {
                td {
                    &.col-site {
                        
                        .rating {
                            margin-top: 5px;
                            margin-bottom: 0;
                        }
                    }
                    &.col-scor {
                        font-family: $regular-font;
                        font-size: 10px;

                        span {
                            font-family: $bold-font;
                            font-size: 43px;
                        }

                        div {
                            a {
                                text-transform: uppercase;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
}

.second-title {
    text-transform: uppercase;
    font-size: 35px;
}

.page-title {
    font-size: 46px;
    text-transform: uppercase;
}

.last-update {
    color: #919191;
}