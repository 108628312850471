@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&family=Source+Sans+Pro&display=swap');

@font-face {
    font-family: 'FatFrank-Free';
    src: url('../fonts/FatFrank-Free/FatFrank-Free.eot');
    src: url('../fonts/FatFrank-Free/FatFrank-Free.eot?#iefix') format('embedded-opentype'),
         url('../fonts/FatFrank-Free/FatFrank-Free.svg#FatFrank-Free') format('svg'),
         url('../fonts/FatFrank-Free/FatFrank-Free.ttf') format('truetype'),
         url('../fonts/FatFrank-Free/FatFrank-Free.woff') format('woff'),
         url('../fonts/FatFrank-Free/FatFrank-Free.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
